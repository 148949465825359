<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Liquidación Prorrateo</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Liquidación Prorrateo</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <!-- Card Filtros Generales -->
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div
                      class="alert alert-default-warning alert-dismissible border border-warning"
                    >
                      <h5>
                        <i class="icon fas fa-exclamation-triangle"></i>
                        Atención!
                      </h5>
                      La liquidación solo se puede realizar una vez. <br />
                      Le recomendamos que revise cuidadosamente todos los
                      detalles antes de solicitar la liquidación para asegurar
                      de que toda la información sea correcta y esté completa.
                    </div>
                  </div>
                  <!-- Campo Sitio -->
                  <div class="col-md-3">
                    <label for="sitios">Sitio</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="sitio"
                      placeholder="Sitio"
                      label="nombre"
                      :options="listasForms.sitios"
                      @input="selectSitio()"
                      multiple
                    ></v-select>
                  </div>
                  <!-- Campo Tipo Consulta -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="tipo_consulta">Tipo Consulta</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="tipo_consulta"
                        placeholder="Tipo"
                        label="descripcion"
                        :options="listasForms.tipo_consultas"
                        @input="selectTipoConsulta()"
                        multiple
                      ></v-select>
                    </div>
                  </div>
                  <!-- Ventana Enturnamiento -->
                  <div
                    class="col-md-3"
                    v-show="
                      filtros.sitio_id &&
                        filtros.sitio_id.length > 0 &&
                        filtros.tipo_consulta &&
                        filtros.tipo_consulta.length > 0
                    "
                  >
                    <label for="origen">Ventanas Enturnamiento</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ventanaEnturnamiento"
                      placeholder="Ventanas Enturnamiento"
                      label="nombre"
                      :options="listasForms.ventanasEnturne"
                      @input="selectVentana()"
                    ></v-select>
                  </div>
                  <!-- Campo Cargar -->
                  <div
                    class="col-md-2 mt-4"
                    v-if="
                      filtros.sitio_id &&
                        filtros.sitio_id.length > 0 &&
                        filtros.tipo_consulta &&
                        filtros.tipo_consulta.length > 0 &&
                        filtros.ventana_enturnamiento_id
                    "
                  >
                    <button
                      type="button"
                      class="btn bg-gradient-navy"
                      @click="getIndex(1)"
                    >
                      <i class="fas fa-sync fa-spin"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card Viajes - Cochadas -->
            <div
              class="card card-primary card-outline card-outline-tabs"
              v-show="viajesVentanaGroup && viajesVentanaGroup.length > 0"
            >
              <div class="card-header p-0 border-bottom-0">
                <ul
                  class="nav nav-tabs"
                  id="custom-tabs-four-tab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-viajes"
                      data-toggle="pill"
                      href="#viajes"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.liquidacionProrrateo.showViajes'
                        )
                      "
                      >Viajes Agrupamiento</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-cochadas"
                      data-toggle="pill"
                      href="#cochadas"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.liquidacionProrrateo.showCochadas'
                        )
                      "
                      >Cochadas</a
                    >
                  </li>
                </ul>
              </div>
              <div class="card-body">
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="viajes">
                    <!-- Reloj -->
                    <div class="row">
                      <div class="col-md-3"></div>
                      <div class="col-md-5 pt-2">
                        <div
                          class="card"
                          style="background-color: #001f3f; padding: 10px; text-align: center;"
                        >
                          <div
                            class="d-flex align-items-center justify-content-center"
                          >
                            <i
                              class="far fa-clock text-white"
                              style="font-size: 24px; margin-right: 8px;"
                            ></i>
                            <div
                              style="color: #daf6ff; font-size: 20px; text-shadow: 0 0 30px rgba(10, 175, 230, 1), 0 0 30px rgba(10, 175, 230, 0);"
                            >
                              {{ fecha_actual }} -
                            </div>
                            <timeless-clock
                              class="ml-2"
                              style="color: #daf6ff; font-size: 20px; text-shadow: 0 0 30px rgba(10, 175, 230, 1), 0 0 30px rgba(10, 175, 230, 0);"
                            ></timeless-clock>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Agrupamiento Viajes -->
                    <div
                      class="row mt-2"
                      v-if="viajesVentanaGroup && viajesVentanaGroup.length > 0"
                    >
                      <div
                        class="col-md-12"
                        v-for="(groupViaje, indice) in viajesVentanaGroup"
                        :key="indice"
                      >
                        <div class="card">
                          <div
                            class="card-header alert-default-success pt-2 pb-2"
                          >
                            <div
                              class="card-title"
                              style="font-size: 13px; text-shadow: 0 0 30px rgba(10, 175, 230, 1),0 0 30px rgba(10, 175, 230, 0);"
                            >
                              <strong
                                >Sitio Cargue:
                                {{ groupViaje.sitioCargue }}
                                |</strong
                              >
                              <strong>
                                Tipo Producto:
                                {{ groupViaje.tipoProducto }} |
                              </strong>
                              <strong
                                >Vehi. Disponibles:
                                {{ groupViaje.datos.length }}</strong
                              >
                              <span class="ml-2">
                                <VueFeather
                                  style="cursor: pointer; color: navy;"
                                  @click="addCochada(groupViaje, indice)"
                                  type="plus-circle"
                                  v-if="
                                    $store.getters.can(
                                      'hidrocarburos.liquidacionProrrateo.agregarCochada'
                                    )
                                  "
                                ></VueFeather>
                              </span>
                            </div>
                            <div class="card-tools">
                              <button
                                type="button"
                                class="btn btn-tool"
                                data-card-widget="collapse"
                              >
                                <i class="fas fa-minus"></i>
                              </button>
                            </div>
                          </div>

                          <div class="card-body">
                            <table
                              id="ventanasViaje"
                              class="table table-bordered table-borderless table-striped table-hover table-sm"
                              style="font-size: 0.85em;"
                            >
                              <thead class="thead text-center">
                                <tr>
                                  <th>No. Viaje</th>
                                  <th>Liquidacion Guía</th>
                                  <th>No. Guía</th>
                                  <th>Tipo</th>
                                  <th>Empresa</th>
                                  <th>Cabezote</th>
                                  <th>Remolque</th>
                                  <th>Conductor</th>
                                  <th>Barrilaje Calculado</th>
                                  <th>Turno Activo</th>
                                </tr>
                              </thead>
                              <draggable
                                v-model="groupViaje.datos"
                                tag="tbody"
                                :group="{
                                  name: 'items',
                                  pull: true,
                                  put: true,
                                }"
                              >
                                <tr
                                  v-for="(data, index) of groupViaje.datos"
                                  :key="index"
                                >
                                  <td class="text-center">
                                    {{ data.id }}
                                  </td>
                                  <td
                                    class="text-center"
                                    v-if="data.liquidacion_guia"
                                    style="font-size: 12px;"
                                  >
                                    <span class="badge bg-success"
                                      >Ya se puede generar guía</span
                                    >
                                  </td>
                                  <td
                                    class="text-center"
                                    v-else
                                    style="font-size: 12px;"
                                  >
                                    <span class="badge bg-danger"
                                      >Sin Liquidar Guía</span
                                    >
                                  </td>
                                  <!-- Guia -->
                                  <td class="text-center">
                                    <span
                                      class="badge badge-success"
                                      v-if="
                                        data.guia !== null &&
                                          data.guia !== undefined
                                      "
                                    >
                                      {{ data.guia.numero }}-{{
                                        data.guia.digito_verificacion
                                      }}
                                    </span>
                                    <span
                                      class="badge badge-success"
                                      v-else-if="data.num_guia != null"
                                    >
                                      {{ data.num_guia }}
                                    </span>
                                    <span class="badge badge-danger" v-else>
                                      Sin Asignar
                                    </span>
                                  </td>
                                  <!-- Tio Operación -->
                                  <td class="text-center">
                                    <div v-if="data.tipo_operacion">
                                      <span class="badge bg-navy">{{
                                        data.tipo_operacion == 2
                                          ? "Ventas A Terceros"
                                          : data.tipo_operacion == 3
                                          ? "Regalias"
                                          : "Compra sin transporte"
                                      }}</span>
                                    </div>
                                    <div v-else>
                                      <span class="badge bg-navy">
                                        Ventas
                                      </span>
                                    </div>
                                  </td>
                                  <!-- Empresa Transportadora  -->
                                  <td class="text-center">
                                    <div v-if="data.transportadora">
                                      {{ data.transportadora.razon_social }}
                                    </div>
                                    <div v-else>
                                      {{ data.empresa }}
                                    </div>
                                  </td>
                                  <!-- Vehículo  -->
                                  <td class="text-center">
                                    <div v-if="data.vehiculo_id">
                                      {{ data.vehiculo.placa }}
                                    </div>
                                    <div v-else>
                                      {{ data.vehiculo }}
                                    </div>
                                  </td>
                                  <!-- Remolque  -->
                                  <td class="text-center">
                                    <div v-if="data.remolque_id">
                                      {{ data.remolque.placa }}
                                    </div>
                                    <div v-else>
                                      {{ data.remolque }}
                                    </div>
                                  </td>
                                  <!-- Conductor  -->
                                  <td class="text-center">
                                    <div v-if="data.conductor_id">
                                      {{ data.conductor.nombres }}
                                      {{ data.conductor.apellidos }}
                                    </div>
                                    <div v-else>
                                      {{ data.conductor }}
                                    </div>
                                  </td>
                                  <!-- Conductor  -->
                                  <td class="text-center">
                                    <span class="badge bg-navy">
                                      {{ data.volumen_calculado }}
                                    </span>
                                  </td>
                                  <!-- Posición -->
                                  <td class="text-center">
                                    {{ index + 1 }}
                                  </td>
                                </tr>
                              </draggable>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Ventanas Enturnamiento -->
                    <div class="row" v-if="cochadas && cochadas.length > 0">
                      <div class="card-navy col-md-12">
                        <div class="card-header pt-2 pb-2">
                          <a
                            data-toggle="collapse"
                            href="#cochadas_liqui"
                            aria-expanded="true"
                            class="d-block"
                            style="border: none"
                          >
                            <div class="row">
                              <i class="fa fa-chevron-down pull-right"></i>
                              <b class="ml-2"
                                >Agrupación Viajes
                                <small>(Liquidación)</small></b
                              >
                            </div></a
                          >
                        </div>
                        <div id="cochadas_liqui" class="collapse show">
                          <div class="card card-body">
                            <div
                              class="col-md-12 mt-2"
                              v-for="(cochada, k) in cochadas"
                              :key="k"
                            >
                              <div class="row">
                                <div class="col-md-12">
                                  <!-- Quitar Cochada -->
                                  <button
                                    type="button"
                                    class="btn alert-default-danger border border-danger btn-sm"
                                    style="border-radius: 0.8rem;"
                                    @click="quitarCochada(k)"
                                    v-if="
                                      cochada.viajes &&
                                        cochada.viajes.length == 0
                                    "
                                  >
                                    <i class="fas fa-trash-alt"></i>
                                    Quitar Grupo
                                  </button>
                                  <!-- Descargar Plantiila -->
                                  <button
                                    type="button"
                                    class="btn alert-default-success border border-success btn-sm"
                                    style="border-radius: 0.8rem;"
                                    @click="planilla(cochada.viajes)"
                                    v-if="
                                      $store.getters.can(
                                        'hidrocarburos.liquidacionProrrateo.planilla'
                                      ) &&
                                        cochada.viajes &&
                                        cochada.viajes.length > 0
                                    "
                                  >
                                    <i class="far fa-file-excel"></i>
                                    Plantilla
                                  </button>
                                  <!-- Subir Plantilla -->
                                  <button
                                    type="button"
                                    class="btn alert-default-primary border border-primary btn-sm ml-1"
                                    style="border-radius: 0.8rem;"
                                    data-toggle="modal"
                                    data-target="#modal_calc_prorrateo"
                                    @click="
                                      changeIndiceViaje(cochada.viajes, k, 1)
                                    "
                                    v-if="
                                      $store.getters.can(
                                        'hidrocarburos.liquidacionProrrateo.subirPlanilla'
                                      ) &&
                                        cochada.viajes &&
                                        cochada.viajes.length > 0
                                    "
                                  >
                                    <i class="fas fa-cloud-upload-alt"></i>
                                    Subir Plantilla
                                  </button>
                                  <!-- Visualización Prorrateo -->
                                  <button
                                    type="button"
                                    class="btn alert-default-info border border-info btn-sm ml-1"
                                    style="border-radius: 0.8rem;"
                                    data-toggle="modal"
                                    data-target="#modal_data_prorrateo"
                                    @click="
                                      changeIndiceViaje(cochada.viajes, k, 2)
                                    "
                                    v-if="
                                      $store.getters.can(
                                        'hidrocarburos.liquidacionProrrateo.subirPlanilla'
                                      ) &&
                                        cochada.viajes &&
                                        cochada.viajes.length > 0 &&
                                        cochada.prorrateo.data.length > 0
                                    "
                                  >
                                    <i class="fas fa-eye"></i> Visualizar
                                    Prorrateo
                                  </button>
                                  <!-- Guardar Cochada -->
                                  <button
                                    type="button"
                                    class="btn alert-default-success border border-success btn-sm ml-1"
                                    style="border-radius: 0.8rem;"
                                    data-toggle="modal"
                                    data-target="#modal_guardar_cochada"
                                    @click="datosCochada(cochada.viajes, k)"
                                    v-if="
                                      $store.getters.can(
                                        'hidrocarburos.liquidacionProrrateo.saveCochada'
                                      ) &&
                                        cochada.viajes &&
                                        cochada.viajes.length > 0
                                    "
                                  >
                                    <i class="fa fa-save"></i> Guardar Cochada
                                  </button>
                                </div>
                              </div>
                              <div class="card-sitio mt-2">
                                <div class="card-left">
                                  <div class="vertical-text">
                                    <b
                                      class="text-center"
                                      style="font-size: 10px;"
                                    >
                                      <span
                                        v-for="(campo,
                                        i) in cochada.nombreCampo"
                                        :key="i"
                                      >
                                        {{ campo }} <br
                                      /></span>
                                    </b>
                                  </div>
                                </div>
                                <div class="card-content w-100 p-4">
                                  <table
                                    id="dataViaje"
                                    class="table table-bordered table-borderless table-striped table-hover table-sm"
                                    style="font-size: 0.85em;"
                                  >
                                    <thead class="thead text-center">
                                      <tr>
                                        <th>No. Viaje</th>
                                        <th>No. Guía</th>
                                        <th>No. Ticket</th>
                                        <th>Tipo</th>
                                        <th>Empresa</th>
                                        <th>Cabezote</th>
                                        <th>Remolque</th>
                                        <th>Conductor</th>
                                        <th>Barrilaje Calculado</th>
                                        <th>Fecha Turno</th>
                                        <th>Turno Activo</th>
                                      </tr>
                                    </thead>
                                    <draggable
                                      v-model="cochada.viajes"
                                      tag="tbody"
                                      :group="{
                                        name: 'items',
                                        pull: true,
                                        put: true,
                                      }"
                                      @add="(evt) => onAddItem(evt, cochada)"
                                    >
                                      <tr
                                        v-for="(viaje, j) of cochada.viajes"
                                        :key="j"
                                      >
                                        <td class="text-center">
                                          {{ viaje.id }}
                                        </td>
                                        <!-- Guia -->
                                        <td class="text-center">
                                          <span
                                            class="badge badge-success"
                                            v-if="
                                              viaje.guia !== null &&
                                                viaje.guia !== undefined
                                            "
                                          >
                                            {{ viaje.guia.numero }}-{{
                                              viaje.guia.digito_verificacion
                                            }}
                                          </span>
                                          <span
                                            class="badge badge-success"
                                            v-else-if="viaje.num_guia != null"
                                          >
                                            {{ viaje.num_guia }}
                                          </span>
                                          <span
                                            class="badge badge-danger"
                                            v-else
                                          >
                                            Sin Asignar
                                          </span>
                                        </td>
                                        <!-- Ticket -->
                                        <td
                                          class="text-center"
                                          v-if="
                                            viaje.ticket !== null &&
                                              viaje.ticket !== undefined
                                          "
                                        >
                                          <span class="badge badge-success">
                                            {{ viaje.ticket.id }}
                                          </span>
                                        </td>
                                        <td v-else class="text-center">
                                          <span class="badge badge-danger">
                                            Sin Asignar
                                          </span>
                                        </td>
                                        <!--  -->
                                        <td class="text-center">
                                          <div v-if="viaje.tipo_operacion">
                                            <span class="badge bg-navy">{{
                                              viaje.tipo_operacion == 2
                                                ? "Ventas A Terceros"
                                                : viaje.tipo_operacion == 3
                                                ? "Regalias"
                                                : "Compra sin transporte"
                                            }}</span>
                                          </div>
                                          <div v-else>
                                            <span class="badge bg-navy">
                                              Ventas
                                            </span>
                                          </div>
                                        </td>
                                        <!-- Empresa Transportadora  -->
                                        <td class="text-center">
                                          <div v-if="viaje.transportadora">
                                            {{
                                              viaje.transportadora.razon_social
                                            }}
                                          </div>
                                          <div v-else>
                                            {{ viaje.empresa }}
                                          </div>
                                        </td>
                                        <!-- Vehículo  -->
                                        <td class="text-center">
                                          <div v-if="viaje.vehiculo_id">
                                            {{ viaje.vehiculo.placa }}
                                          </div>
                                          <div v-else>
                                            {{ viaje.vehiculo }}
                                          </div>
                                        </td>
                                        <!-- Remolque  -->
                                        <td class="text-center">
                                          <div v-if="viaje.remolque_id">
                                            {{ viaje.remolque.placa }}
                                          </div>
                                          <div v-else>
                                            {{ viaje.remolque }}
                                          </div>
                                        </td>
                                        <!-- Conductor  -->
                                        <td class="text-center">
                                          <div v-if="viaje.conductor_id">
                                            {{ viaje.conductor.nombres }}
                                            {{ viaje.conductor.apellidos }}
                                          </div>
                                          <div v-else>
                                            {{ viaje.conductor }}
                                          </div>
                                        </td>
                                        <!-- Conductor  -->
                                        <td class="text-center">
                                          <span class="badge bg-navy">
                                            {{ viaje.volumen_calculado }}
                                          </span>
                                        </td>
                                        <!-- Fecha Viaje -->
                                        <td class="text-center">
                                          {{ viaje.fecha_enturne_cargue }}
                                        </td>
                                        <!-- Posición -->
                                        <td class="text-center">
                                          {{ j + 1 }}
                                        </td>
                                      </tr>
                                    </draggable>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Modal Subir Plantilla -->
                    <div class="modal fade" id="modal_calc_prorrateo">
                      <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                          <div
                            class="modal-header bg-frontera-top-left pt-2 pb-2"
                          >
                            <h4 class="modal-title text-white">
                              Datos Cálculo Tk
                            </h4>
                            <button
                              type="button"
                              class="close text-white"
                              data-dismiss="modal"
                              aria-label="Close"
                              id="close-modal"
                              ref="closeModalPlant"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div class="row">
                              <!-- Nombre -->
                              <div class=" form-group col-md-3">
                                <label for="descripcion">Nombre</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="Nombre"
                                    v-model="form.nombre"
                                    :class="
                                      $v.form.nombre.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i class="fas fa-text-height"></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <!-- Temperatura -->
                              <div class=" form-group col-md-3">
                                <label for="temp">Temperatura</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="Temperatura"
                                    v-model="form.temperatura"
                                    :class="
                                      $v.form.temperatura.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i class="fas fa-thermometer-quarter"></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <!-- Api -->
                              <div class=" form-group col-md-3">
                                <label for="api">Api</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="Api"
                                    v-model="form.api"
                                    :class="
                                      $v.form.api.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i class="fas fa-soap"></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <!-- Bsw -->
                              <div class=" form-group col-md-3">
                                <label for="bsw">BSW</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="BSW"
                                    v-model="form.bsw"
                                    :class="
                                      $v.form.bsw.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i class="fas fa-drum-steelpan"></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <!-- Ctl -->
                              <div class=" form-group col-md-3">
                                <label for="ctl">Ctl</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="CTL"
                                    v-model="form.ctl"
                                    :class="
                                      $v.form.ctl.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i class="fas fa-fill-drip"></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <!-- Gov -->
                              <div class=" form-group col-md-3">
                                <label for="gov">GOV</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="GOV"
                                    v-model="form.gov"
                                    :class="
                                      $v.form.gov.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i class="fas fa-tint"></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <!-- Gsv -->
                              <div class=" form-group col-md-3">
                                <label for="gsv">GSV</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="GSV"
                                    v-model="form.gsv"
                                    :class="
                                      $v.form.gsv.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i
                                        class="fas fa-exclamation-triangle"
                                      ></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <!-- Nsv -->
                              <div class=" form-group col-md-3">
                                <label for="nsv">Nsv</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="NSV"
                                    v-model="form.nsv"
                                    :class="
                                      $v.form.nsv.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i class="fas fa-radiation"></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Importar Plantilla -->
                            <div class="row justify-content-center">
                              <div class="form-group col-md-8">
                                <div
                                  class="card text-center card-footer text-muted shadow"
                                >
                                  <h6><b>Importar Plantilla</b></h6>
                                  <div
                                    class="col-md-12 mt-2"
                                    :class="
                                      plantilla_cargue == null
                                        ? 'alert-default-warning border border-warning'
                                        : 'alert-default-success border border-success'
                                    "
                                  >
                                    <input
                                      type="file"
                                      id="file"
                                      ref="file"
                                      @change="elegirDirectorio()"
                                      accept=".xlsx, .CSV"
                                      class="form-control btn btn-secundary"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="modal-footer justify-content-between"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.liquidacionProrrateo.subirPlanilla'
                              ) &&
                                !$v.form.$invalid &&
                                plantilla_cargue
                            "
                          >
                            <button
                              type="button"
                              class="btn btn-primary"
                              @click="importExcel()"
                            >
                              Subir Plantilla
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Modal Diferencias y Data Prorrateo -->
                    <div
                      class="modal fade"
                      id="modal_data_prorrateo"
                      v-if="indice_cochada != null"
                    >
                      <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                          <div
                            class="modal-header bg-frontera-top-left pt-2 pb-2"
                          >
                            <h4 class="modal-title text-white">
                              Datos Prorrateo
                            </h4>
                            <button
                              type="button"
                              class="close text-white"
                              data-dismiss="modal"
                              aria-label="Close"
                              id="close-modal"
                              ref="closeModalDataPro"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div class="row">
                              <!-- Nombre -->
                              <div class=" form-group col-md-3">
                                <label for="descripcion">Nombre</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="Nombre"
                                    v-model="
                                      cochadas[indice_cochada].prorrateo.tk_data
                                        .nombre
                                    "
                                    disabled
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i class="fas fa-text-height"></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <!-- Temperatura -->
                              <div class=" form-group col-md-3">
                                <label for="temp">Temperatura</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="Temperatura"
                                    v-model="
                                      cochadas[indice_cochada].prorrateo.tk_data
                                        .temperatura
                                    "
                                    disabled
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i class="fas fa-thermometer-quarter"></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <!-- Api -->
                              <div class=" form-group col-md-3">
                                <label for="api">Api</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="Api"
                                    v-model="
                                      cochadas[indice_cochada].prorrateo.tk_data
                                        .api
                                    "
                                    disabled
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i class="fas fa-soap"></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <!-- Bsw -->
                              <div class=" form-group col-md-3">
                                <label for="bsw">BSW</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="BSW"
                                    v-model="
                                      cochadas[indice_cochada].prorrateo.tk_data
                                        .bsw
                                    "
                                    disabled
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i class="fas fa-drum-steelpan"></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <!-- Ctl -->
                              <div class=" form-group col-md-3">
                                <label for="ctl">Ctl</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="CTL"
                                    v-model="
                                      cochadas[indice_cochada].prorrateo.tk_data
                                        .ctl
                                    "
                                    disabled
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i class="fas fa-fill-drip"></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <!-- Gov -->
                              <div class=" form-group col-md-3">
                                <label for="gov">GOV</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="GOV"
                                    v-model="
                                      cochadas[indice_cochada].prorrateo.tk_data
                                        .gov
                                    "
                                    disabled
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i class="fas fa-tint"></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <!-- Gsv -->
                              <div class=" form-group col-md-3">
                                <label for="gsv">GSV</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="GSV"
                                    v-model="
                                      cochadas[indice_cochada].prorrateo.tk_data
                                        .gsv
                                    "
                                    disabled
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i
                                        class="fas fa-exclamation-triangle"
                                      ></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <!-- Nsv -->
                              <div class=" form-group col-md-3">
                                <label for="nsv">Nsv</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="NSV"
                                    v-model="
                                      cochadas[indice_cochada].prorrateo.tk_data
                                        .nsv
                                    "
                                    disabled
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i class="fas fa-radiation"></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Tabla Diferencia -->
                            <div class="row justify-content-center mt-3">
                              <table
                                class="table table-bordered table-striped table-hover table-sm shadow col-md-8"
                              >
                                <tr class="text-center text-white bg-blueB">
                                  <td>
                                    <span><b> VOLUMEN</b></span>
                                  </td>
                                  <td>
                                    <span><b> DATOS TK</b></span>
                                  </td>
                                  <td>
                                    <span><b> TOTAL DATOS</b></span>
                                  </td>
                                  <td>
                                    <span><b> DIFERENCIA</b></span>
                                  </td>
                                  <td>
                                    <span><b> OBSERVACIÓN</b></span>
                                  </td>
                                </tr>
                                <tr class="text-center">
                                  <td>
                                    <span><b>GOV</b></span>
                                  </td>
                                  <td>
                                    <span
                                      ><b>
                                        {{
                                          cochadas[indice_cochada].prorrateo
                                            .tk_data.gov
                                        }}</b
                                      ></span
                                    >
                                  </td>
                                  <td>
                                    <span
                                      ><b>
                                        {{
                                          cochadas[indice_cochada].prorrateo
                                            .totGov
                                        }}</b
                                      ></span
                                    >
                                  </td>
                                  <td>
                                    <span
                                      ><b>
                                        {{
                                          cochadas[indice_cochada].prorrateo
                                            .diffGov
                                        }}</b
                                      ></span
                                    >
                                  </td>
                                  <td>
                                    <span
                                      :class="
                                        cochadas[indice_cochada].prorrateo
                                          .diffGov == 0
                                          ? 'text-success'
                                          : 'text-danger'
                                      "
                                      ><b>
                                        {{
                                          cochadas[indice_cochada].prorrateo
                                            .diffGov == 0
                                            ? "Prorrateo exitoso, imprima datos"
                                            : "Prorrateo erroneo, validar datos"
                                        }}
                                      </b></span
                                    >
                                  </td>
                                </tr>
                                <tr class="text-center">
                                  <td>
                                    <span><b>GSV</b></span>
                                  </td>
                                  <td>
                                    <span
                                      ><b>
                                        {{
                                          cochadas[indice_cochada].prorrateo
                                            .tk_data.gsv
                                        }}</b
                                      ></span
                                    >
                                  </td>
                                  <td>
                                    <span
                                      ><b>
                                        {{
                                          cochadas[indice_cochada].prorrateo
                                            .totGsv
                                        }}</b
                                      ></span
                                    >
                                  </td>
                                  <td>
                                    <span
                                      ><b>
                                        {{
                                          cochadas[indice_cochada].prorrateo
                                            .diffGsv
                                        }}</b
                                      ></span
                                    >
                                  </td>
                                  <td>
                                    <span
                                      :class="
                                        cochadas[indice_cochada].prorrateo
                                          .diffGsv == 0
                                          ? 'text-success'
                                          : 'text-danger'
                                      "
                                      ><b>
                                        {{
                                          cochadas[indice_cochada].prorrateo
                                            .diffGsv == 0
                                            ? "Prorrateo exitoso, imprima datos"
                                            : "Prorrateo erroneo, validar datos"
                                        }}
                                      </b></span
                                    >
                                  </td>
                                </tr>
                                <tr class="text-center">
                                  <td>
                                    <span><b>NSV</b></span>
                                  </td>
                                  <td>
                                    <span
                                      ><b>
                                        {{
                                          cochadas[indice_cochada].prorrateo
                                            .tk_data.nsv
                                        }}</b
                                      ></span
                                    >
                                  </td>
                                  <td>
                                    <span
                                      ><b>
                                        {{
                                          cochadas[indice_cochada].prorrateo
                                            .totNsv
                                        }}</b
                                      ></span
                                    >
                                  </td>
                                  <td>
                                    <span
                                      ><b>
                                        {{
                                          cochadas[indice_cochada].prorrateo
                                            .diffNsv
                                        }}</b
                                      ></span
                                    >
                                  </td>
                                  <td>
                                    <span
                                      :class="
                                        cochadas[indice_cochada].prorrateo
                                          .diffNsv == 0
                                          ? 'text-success'
                                          : 'text-danger'
                                      "
                                      ><b>
                                        {{
                                          cochadas[indice_cochada].prorrateo
                                            .diffNsv == 0
                                            ? "Prorrateo exitoso, imprima datos"
                                            : "Prorrateo erroneo, validar datos"
                                        }}
                                      </b></span
                                    >
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <!-- Tabla Cargue Prorrateo -->
                            <div class="row mt-3">
                              <div class="col-md-12">
                                <div class="btn-group float-right">
                                  <button
                                    class="btn alert-default-info btn-sm"
                                    type="button"
                                    @click="save()"
                                    v-if="
                                      $store.getters.can(
                                        'hidrocarburos.liquidacionProrrateo.saveLiquidacion'
                                      ) &&
                                        cochadas[indice_cochada].prorrateo
                                          .diffGov == 0 &&
                                        cochadas[indice_cochada].prorrateo
                                          .diffGsv == 0 &&
                                        cochadas[indice_cochada].prorrateo
                                          .diffNsv == 0
                                    "
                                  >
                                    <i class="fas fa-save"></i>
                                    Guardar Liquidación
                                  </button>
                                </div>
                              </div>
                              <div class="col-md-12 mt-3 table-responsive">
                                <table
                                  class="table table-bordered table-striped table-hover table-sm"
                                >
                                  <thead class="bg-dark">
                                    <tr>
                                      <th
                                        rowspan="2"
                                        class="text-center p-3"
                                        style="min-width: 8em"
                                      >
                                        Vehículo
                                      </th>
                                      <th
                                        rowspan="2"
                                        class="text-center p-3"
                                        style="min-width: 10em"
                                      >
                                        Producto Liquido
                                      </th>
                                      <th rowspan="2" class="text-center p-3">
                                        GOV
                                      </th>
                                      <th
                                        rowspan="2"
                                        class="text-center p-3"
                                        style="min-width: 5em"
                                      >
                                        GSV
                                      </th>
                                      <th
                                        rowspan="2"
                                        class="text-center p-3"
                                        style="min-width: 5em"
                                      >
                                        NSV
                                      </th>
                                      <th
                                        rowspan="2"
                                        class="text-center p-3"
                                        style="min-width: 5em"
                                      >
                                        CTL
                                      </th>
                                      <th rowspan="2" class="text-center p-3">
                                        API
                                      </th>
                                      <th rowspan="2" class="text-center p-3">
                                        Temperatura
                                      </th>
                                      <th
                                        rowspan="2"
                                        class="text-center p-3"
                                        style="min-width: 8em"
                                      >
                                        BSW Prt
                                      </th>
                                      <th rowspan="2" class="text-center p-3">
                                        Sellos
                                      </th>
                                      <th rowspan="2" class="text-center p-3">
                                        Presión
                                      </th>
                                      <th
                                        rowspan="2"
                                        class="text-center p-3"
                                        style="min-width: 5em"
                                      >
                                        CSW
                                      </th>
                                      <th rowspan="2" class="text-center p-3">
                                        Azufre
                                      </th>
                                      <th rowspan="2" class="text-center p-3">
                                        SAL
                                      </th>
                                      <th rowspan="2" class="text-center p-3">
                                        Sedimento
                                      </th>
                                      <th rowspan="2" class="text-center p-3">
                                        RVP
                                      </th>
                                      <th
                                        colspan="2"
                                        class="text-center p-2"
                                        style="min-width: 15em"
                                      >
                                        Insertidumbre Expandida
                                      </th>
                                    </tr>
                                    <tr class="text-center">
                                      <th>BLS</th>
                                      <th>%</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(carguePro, index) in cochadas[
                                        this.indice_cochada
                                      ].prorrateo.data"
                                      :key="index"
                                    >
                                      <td class="text-center">
                                        {{ carguePro.placa }}
                                      </td>
                                      <td class="text-center">
                                        {{ carguePro.producto_liquido }}
                                      </td>
                                      <td class="text-center">
                                        {{ carguePro.gov }}
                                      </td>
                                      <td class="text-center">
                                        {{ carguePro.gsv }}
                                      </td>
                                      <td
                                        class="text-center"
                                        v-if="carguePro.dataCtl"
                                      >
                                        {{ carguePro.dataCtl.nsv }}
                                      </td>
                                      <td
                                        :class="
                                          carguePro.ctl != carguePro.dataCtl.ctl
                                            ? 'text-center alert-default-warning'
                                            : 'text-center '
                                        "
                                        v-if="carguePro.dataCtl"
                                      >
                                        <div>
                                          <span
                                            :class="
                                              carguePro.ctl !=
                                              carguePro.dataCtl.ctl
                                                ? 'badge alert-default-danger'
                                                : 'badge alert-default-success'
                                            "
                                          >
                                            {{ carguePro.ctl }}
                                          </span>
                                        </div>
                                        <span
                                          class="badge alert-default-success"
                                        >
                                          {{ carguePro.dataCtl.ctl }}
                                        </span>
                                      </td>
                                      <td class="text-center">
                                        {{ carguePro.api }}
                                      </td>
                                      <td class="text-center">
                                        {{ carguePro.temperatura }}
                                      </td>
                                      <td class="text-center">
                                        {{ carguePro.bsw }}
                                      </td>
                                      <td class="text-center">
                                        {{ carguePro.sellos }}
                                      </td>
                                      <td class="text-center">
                                        {{ carguePro.presion }}
                                      </td>
                                      <td
                                        :class="
                                          carguePro.csw != carguePro.dataCtl.csw
                                            ? 'text-center alert-default-warning'
                                            : 'text-center '
                                        "
                                        v-if="carguePro.dataCtl"
                                      >
                                        <div>
                                          <span
                                            :class="
                                              carguePro.csw !=
                                              carguePro.dataCtl.csw
                                                ? 'badge alert-default-danger'
                                                : 'badge alert-default-success'
                                            "
                                          >
                                            {{ carguePro.csw }}
                                          </span>
                                        </div>
                                        <span
                                          class="badge alert-default-success"
                                        >
                                          {{ carguePro.dataCtl.csw }}
                                        </span>
                                      </td>
                                      <td class="text-center">
                                        {{ carguePro.azufre }}
                                      </td>
                                      <td class="text-center">
                                        {{ carguePro.sal_ptb }}
                                      </td>
                                      <td class="text-center">
                                        {{ carguePro.sedimento }}
                                      </td>
                                      <td class="text-center">
                                        {{ carguePro.rvp }}
                                      </td>
                                      <td class="text-center">
                                        {{ carguePro.iincer_exp_porc }}
                                      </td>
                                      <td class="text-center">
                                        {{ carguePro.iincer_exp_bls }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Modal Guardar Cochada -->
                    <div class="modal fade" id="modal_guardar_cochada">
                      <div class="modal-dialog modal-md">
                        <div class="modal-content">
                          <div
                            class="modal-header bg-frontera-top-left pt-2 pb-2"
                          >
                            <h4 class="modal-title text-white">
                              Creación Cochada
                            </h4>
                            <button
                              type="button"
                              class="close text-white"
                              data-dismiss="modal"
                              aria-label="Close"
                              id="close-modal"
                              ref="closeModalCochada"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div class="row">
                              <!-- Nombre -->
                              <div class=" form-group col-md-12">
                                <label for="fecha">Fecha</label>
                                <div class="input-group mb-3">
                                  <input
                                    type="date"
                                    class="form-control form-control-sm"
                                    v-model="formCochada.fecha"
                                    :class="
                                      $v.formCochada.fecha.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text"
                                      ><i class="fas fa-calendar"></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="modal-footer justify-content-between"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.liquidacionProrrateo.saveCochada'
                              ) && !$v.formCochada.$invalid
                            "
                          >
                            <button
                              type="button"
                              class="btn alert-default-primary border border-primary"
                              @click="saveCochada()"
                            >
                              Guardar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="cochadas">
                    <LiquidacionGrupoCochada ref="liquidacionGrupoCochada" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
import { required, minValue } from "vuelidate/lib/validators";
import TimelessClock from "vuejs-timeless-clock";
import VueFeather from "vue-feather";
import draggable from "vuedraggable";
import LiquidacionGrupoCochada from "./LiquidacionGrupoCochada";

export default {
  name: "LiquidacionProrrateoIndex",
  components: {
    TimelessClock,
    vSelect,
    Loading,
    VueFeather,
    draggable,
    LiquidacionGrupoCochada,
  },
  data() {
    return {
      filtros: {
        sitio_id: null,
        ventana_enturnamiento_id: null,
        tipo_consulta: null,
      },
      cargando: false,
      listasForms: {
        sitios: [],
        tipo_consultas: [],
        ventanasEnturne: [],
      },
      form: {
        nombre: null,
        temperatura: null,
        api: null,
        bsw: null,
        ctl: null,
        gov: null,
        gsv: null,
        nsv: null,
      },
      formCochada: {
        ventana_enturnamiento_cargue_id: null,
        fecha: null,
        estado: 1,
        detalle: [],
      },
      plantilla_cargue: null,
      indice_cochada: null,
      fecha_actual: moment().format("YYYY-MM-DD"),
      sitio: [],
      tipo_consulta: [],
      ventanaEnturnamiento: [],
      viajesVentanaGroup: {},
      cochadas: [],
      viajes: [],
    };
  },

  validations: {
    form: {
      nombre: {
        required,
      },
      temperatura: {
        required,
      },
      api: {
        required,
        minValue: minValue(0),
      },
      bsw: {
        required,
        minValue: minValue(0),
      },
      ctl: {
        required,
        minValue: minValue(0),
      },
      gov: {
        required,
        minValue: minValue(0),
      },
      gsv: {
        required,
        minValue: minValue(0),
      },
      nsv: {
        required,
        minValue: minValue(0),
      },
    },
    formCochada: {
      fecha: {
        required,
      },
    },
  },

  methods: {
    async getIndex(accion) {
      this.cargando = true;
      let me = this;
      this.indice_cochada = null;

      if (accion == 1) {
        this.cochadas = [];
      }
      if (
        this.filtros.sitio_id &&
        this.filtros.tipo_consulta &&
        this.filtros.ventana_enturnamiento_id
      ) {
        await axios
          .get("/api/hidrocarburos/liquidacionGuias/liquidacionProrrateo", {
            params: this.filtros,
          })
          .then((response) => {
            me.viajesVentanaGroup = response.data;
            me.cargando = false;
          });
        me.cargando = false;
      }
      me.cargando = false;
    },

    async buscarVentanasEnturne() {
      let me = this;
      await axios
        .get(
          "api/hidrocarburos/ventanasEnturnamientoOperacion/listaVentanasProrrateo",
          {
            params: {
              sitio_id: me.filtros.sitio_id,
              sitio_type: 1,
              tipo_ventana: 1,
            },
          }
        )
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.ventanasEnturne = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getTipoConsulta() {
      await axios.get("/api/lista/47").then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].numeracion != 2) {
            this.listasForms.tipo_consultas.push(response.data[i]);
          }
        }
      });
    },

    async getSitios() {
      let me = this;
      var url = "api/admin/sitios/lista?estado=1";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getMomentTime() {
      return moment();
    },

    async selectSitio() {
      this.filtros.sitio_id = null;
      this.filtros.ventana_enturnamiento_id = null;
      this.ventanaEnturnamiento = {};
      if (this.sitio) {
        this.filtros.sitio_id = this.sitio.map((s) => s.id);
        await this.buscarVentanasEnturne();
      }
    },

    selectTipoConsulta() {
      this.filtros.tipo_consulta = null;
      if (this.tipo_consulta) {
        this.filtros.tipo_consulta = this.tipo_consulta.map(
          (t) => t.numeracion
        );
      }
    },

    selectVentana() {
      this.filtros.ventana_enturnamiento_id = null;
      if (this.ventanaEnturnamiento) {
        this.filtros.ventana_enturnamiento_id = this.ventanaEnturnamiento.id;
      }
    },

    async addCochada(groupViaje) {
      if (this.cochadas.length > 0) {
        let groups = {
          orden: this.cochadas.length,
          viajes: [],
          prorrateo: {
            tk_data: {
              nombre: null,
              temperatura: null,
              api: null,
              bsw: null,
              ctl: null,
              gov: null,
              gsv: null,
              nsv: null,
              totGov: null,
              totGsv: null,
              totNsv: null,
              diffGov: null,
              diffGsv: null,
              diffNsv: null,
            },
            data: [],
            totGov: 0,
            totGsv: 0,
            totNsv: 0,
            diffGov: 0,
            diffGsv: 0,
            diffNsv: 0,
          },
          nombreCampo: groupViaje.sitioCargue.split(""),
          sitio_cargue: groupViaje.sitioCargue,
          sitio_cargue_id: groupViaje.sitio_cargue_id,
        };
        this.cochadas.push(groups);
      } else {
        let groups = {
          orden: 0,
          viajes: [],
          prorrateo: {
            tk_data: {
              nombre: null,
              temperatura: null,
              api: null,
              bsw: null,
              ctl: null,
              gov: null,
              gsv: null,
              nsv: null,
            },
            data: [],
            totGov: 0,
            totGsv: 0,
            totNsv: 0,
            diffGov: 0,
            diffGsv: 0,
            diffNsv: 0,
          },
          nombreCampo: groupViaje.sitioCargue.split(""),
          sitio_cargue: groupViaje.sitioCargue,
          sitio_cargue_id: groupViaje.sitio_cargue_id,
        };
        this.cochadas.push(groups);
      }
    },

    quitarCochada(index) {
      this.cochadas.splice(index, 1);
    },

    onAddItem(evt, targetCochada) {
      let draggedItem = evt.item._underlying_vm_;
      let originalCochada = this.cochadas.find((c) =>
        c.viajes.includes(draggedItem)
      );

      let originalGroup = this.viajesVentanaGroup.find(
        (group) => group.sitio_cargue_id === draggedItem.sitio_cargue_id
      );

      /* if (draggedItem.sitio_cargue_id !== targetCochada.sitio_cargue_id) {
        this.$swal({
          icon: "error",
          title:
            "El sitio de cargue no coincide. No se puede agregar este viaje.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        if (originalCochada) {
          const itemIndex = originalCochada.viajes.indexOf(draggedItem);
          if (itemIndex > -1) {
            originalCochada.viajes.splice(itemIndex, 1);
          }
        }
        originalGroup.datos.push(draggedItem);
      } */

      /* Si ya el viaje se encuentra liquidado */
      if (draggedItem.liquidacion_guia) {
        this.$swal({
          icon: "error",
          title:
            "El viaje ya se encuentra con una liquidación. No se puede agregar este viaje.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        if (originalCochada) {
          const itemIndex = originalCochada.viajes.indexOf(draggedItem);
          if (itemIndex > -1) {
            originalCochada.viajes.splice(itemIndex, 1);
          }
        }
        originalGroup.datos.push(draggedItem);
      }

      /* Si ya el viaje se encuentra en una cochada */
      if (draggedItem.cochada) {
        this.$swal({
          icon: "error",
          title:
            "El viaje ya se encuentra en una cochada. No se puede agregar este viaje.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        if (originalCochada) {
          const itemIndex = originalCochada.viajes.indexOf(draggedItem);
          if (itemIndex > -1) {
            originalCochada.viajes.splice(itemIndex, 1);
          }
        }
        originalGroup.datos.push(draggedItem);
      }
    },

    planilla(viajes) {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/liquidacionGuias/PlantillaProrrateo",
        data: {
          viajes: viajes,
        },
      })
        .then((response) => {
          let data = response.data;
          location.href = data.url;
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: response.data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    elegirDirectorio() {
      this.plantilla_cargue = this.$refs.file.files[0];
    },

    limpiarPlantilla() {
      const fileInput = document.getElementById("file");
      fileInput.value = null;
      this.plantilla_cargue = null;
    },

    changeIndiceViaje(viajesGroup, indice, opcion) {
      if (opcion == 1) {
        this.viajes = [];
        this.indice_cochada = indice;
        for (let i = 0; i < viajesGroup.length; i++) {
          const result = viajesGroup[i];
          this.viajes.push({
            viaje_id: result.id,
            placa:
              result.vehiculo_id !== undefined
                ? result.vehiculo.placa
                : result.vehiculo,
            volumen_calculado: result.volumen_calculado,
          });
        }
      } else {
        this.indice_cochada = indice;
      }
    },

    async importExcel() {
      this.cargando = true;
      let formData = new FormData();
      formData.append("file", this.plantilla_cargue);
      formData.append("viajes", JSON.stringify(this.viajes));
      formData.append("form", JSON.stringify(this.form));
      await axios
        .post(
          "/api/hidrocarburos/liquidacionGuias/importExcelTempProrrateo",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(async (response) => {
          this.cargando = false;
          let result_content =
            response.data.error.length > 0
              ? `<div>
                <p class="text-danger">
                  <strong>El archivo no se cargo.</strong>
                </p>
              </div>`
              : `<div>
                <p class="text-success">
                  <strong>El archivo se cargo exitosamente.</strong>
                </p>
              </div>`;
          if (response.data.error.length > 0) {
            result_content += `<h4>Errores encontrados.</h4>`;
            response.data.error.forEach(
              (err) => (result_content += `<div><small>${err}</small></div>`)
            );
          }
          await this.$swal({
            title: "Resultado de la carga.",
            html: result_content,
            icon: response.data.error.length > 0 ? "error" : "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.value) {
              if (response.data.success) {
                this.cochadas[this.indice_cochada].prorrateo.data =
                  response.data.success.cargue;
                this.cochadas[this.indice_cochada].prorrateo.tk_data =
                  response.data.success.form;
                let totGov = 0;
                let totGsv = 0;
                let totNsv = 0;
                for (
                  let i = 0;
                  i < this.cochadas[this.indice_cochada].prorrateo.data.length;
                  i++
                ) {
                  totGov += parseFloat(
                    this.cochadas[this.indice_cochada].prorrateo.data[i].gov
                  );
                  totGsv += parseFloat(
                    this.cochadas[this.indice_cochada].prorrateo.data[i].gsv
                  );
                  totNsv += parseFloat(
                    this.cochadas[this.indice_cochada].prorrateo.data[i].nsv
                  );

                  /* Función ctl, cpl */
                  let ctl = await this.getDataCtlCpl(
                    this.cochadas[this.indice_cochada].prorrateo.data[i]
                  );

                  this.cochadas[this.indice_cochada].prorrateo.data[
                    i
                  ].dataCtl = ctl;
                }
                this.cochadas[
                  this.indice_cochada
                ].prorrateo.totGov = totGov.toFixed(2);
                this.cochadas[
                  this.indice_cochada
                ].prorrateo.totGsv = totGsv.toFixed(2);
                this.cochadas[
                  this.indice_cochada
                ].prorrateo.totNsv = totNsv.toFixed(2);
                let diffGov =
                  parseFloat(
                    this.cochadas[this.indice_cochada].prorrateo.tk_data.gov
                  ) - parseFloat(totGov);
                let diffGsv =
                  parseFloat(
                    this.cochadas[this.indice_cochada].prorrateo.tk_data.gsv
                  ) - parseFloat(totGsv);
                let diffNsv =
                  parseFloat(
                    this.cochadas[this.indice_cochada].prorrateo.tk_data.nsv
                  ) - parseFloat(totNsv);

                this.cochadas[
                  this.indice_cochada
                ].prorrateo.diffGov = diffGov.toFixed(2);
                this.cochadas[
                  this.indice_cochada
                ].prorrateo.diffGsv = diffGsv.toFixed(2);
                this.cochadas[
                  this.indice_cochada
                ].prorrateo.diffNsv = diffNsv.toFixed(2);
              }
              await this.limpiarPlantilla();
              this.$refs.closeModalPlant.click();
              this.indice_cochada = null;
            }
          });
        })
        .catch(async (e) => {
          this.cargando = false;
          await this.$swal({
            icon: "error",
            title:
              "Ha ocurrido un error, por favor intente la acción nuevamente.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getDataCtlCpl(dataCargue) {
      let ctlCpl = {
        ctl: null,
        gsv: null,
        csw: null,
        nsv: null,
      };
      let ctl = null;
      /* Variable ctl */
      ctl = parseFloat(dataCargue.gsv / dataCargue.gov);
      // Se valida la información del formulario principal
      ctlCpl.ctl = parseFloat(ctl.toFixed(5));
      ctlCpl.gsv = parseFloat((dataCargue.gov * ctl).toFixed(2));
      ctlCpl.csw = parseFloat((1 - dataCargue.bsw / 100).toFixed(5));
      ctlCpl.nsv = parseFloat(ctlCpl.gsv * ctlCpl.csw.toFixed(2));

      return ctlCpl;
    },

    async save() {
      let data = {
        data_tanque: this.cochadas[this.indice_cochada].prorrateo.tk_data,
        ventana_enturnamiento_cargue_id: this.filtros.ventana_enturnamiento_id,
        cochada_id: this.cochadas[this.indice_cochada].id,
        cargue: this.cochadas[this.indice_cochada].prorrateo.data,
        accion: 1,
      };
      await this.$swal({
        title: "Esta seguro de guardar esta liquidación?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Guardar!",
      }).then(async (result) => {
        if (result.value) {
          await axios({
            method: "POST",
            url: "/api/hidrocarburos/liquidacionGuias/saveProrrateo",
            data: data,
          })
            .then(async () => {
              await this.$swal({
                icon: "success",
                title: "La liquidación se guardó exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              await this.$refs.closeModalDataPro.click();
              this.cochadas.splice(this.indice_cochada, 1);
              await this.getIndex();
            })
            .catch(async (e) => {
              await this.$swal({
                icon: "error",
                title: "Ocurrió un error, vuelva a intentarlo... " + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    datosCochada(viajes, indice) {
      let v = [];
      this.indice_cochada = indice;
      for (let k = 0; k < viajes.length; k++) {
        const viaje = viajes[k];
        v.push({
          viaje_id: viaje.id,
          viaje_type:
            viaje.vehiculo_id !== undefined
              ? "App\\Viaje"
              : "App\\DetGuiaRegalias",
        });
      }

      this.formCochada = {
        user_id: this.$store.state.user.id,
        ventana_enturnamiento_cargue_id: this.filtros.ventana_enturnamiento_id,
        fecha: null,
        estado: 1,
        detalle: v,
      };
    },

    saveCochada() {
      this.$swal({
        title: "Esta seguro de guardar esta Cochada?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Guardar!",
      }).then((result) => {
        if (result.value) {
          axios({
            method: "POST",
            url: "/api/hidrocarburos/liquidacionGuias/saveCochada",
            data: this.formCochada,
          })
            .then(() => {
              this.$refs.closeModalCochada.click();
              this.limpiarFormCochada();
              this.cochadas.splice(this.indice_cochada, 1);
              this.indice_cochada = null;
              this.$swal({
                icon: "success",
                title: "La cochada se guardó exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title: "Ocurrió un error, vuelva a intentarlo... " + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    limpiarFormCochada() {
      this.formCochada = {
        ventana_enturnamiento_cargue_id: null,
        fecha: null,
        estado: 1,
        detalle: [],
      };
    },
  },

  async mounted() {
    await this.getTipoConsulta();
    await this.getSitios();
  },
};
</script>
<style>
.vertical-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  color: white;
}
.card-sitio {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 0.8rem;
  overflow: hidden;
}
.card-left {
  border: 10px solid #800a0a;
  background-color: #800a0a;
  color: white;
  padding: 10px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
}
.card-right {
  padding: 10px;
  flex: 1;
}
.card-content {
  padding: 10px;
}
.bg-blueB {
  background-color: #062d6f;
}
</style>
